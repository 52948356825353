import React, { useEffect, useState } from 'react';
import {
  manageLevelTimeout,
  setDefaultLevels,
  setDefaultRandomText,
} from '../../Functions';
import { HackableContainer } from '../Molucules';
import { ContactForm } from '../Organism';
import { LinkButton, AutoplayVideo, ScrollingText } from '../Atoms';
import './Pages.css';
import { ProductDisplayContainer } from '../Molucules/ProductDisplayContainer';

export const Home = (props) => {
  const { levelTimings, maxLevel, setTransition } = props;

  // either get the altered level for this page if it is 2> lower than the maxLevel,
  // get the level of this page, or 0.
  const [level, setLevel] = useState(() => {
    const savedHome = parseInt(localStorage.getItem('Home'));
    if (isNaN(savedHome)) {
      localStorage.setItem('Home', '0');
    }
    if (savedHome <= maxLevel - 1) {
      localStorage.setItem('Home', String(maxLevel - 1));
      return maxLevel - 1;
    } else {
      return savedHome || 0;
    }
  });

  // set the timer. When the timer finishes, it will change the state in local storage and re-render.
  useEffect(() => {
    //TODO change this value when more levels availible
    if (level < 3) {
      const timeout = setTimeout(() => {
        manageLevelTimeout(level, 'Home', setLevel);
        setTransition(true);
      }, levelTimings[level]);
      return () => clearTimeout(timeout);
    }
  }, [level, setTransition, levelTimings]);

  useEffect(() => {
    const localLevel = localStorage.getItem('Level');
    if (!localLevel) {
      setDefaultLevels();
      setDefaultRandomText();
      setLevel(localStorage.getItem('Level'));
    }
  }, []);

  const homeOptions = localStorage?.getItem('homeText')?.split(',') || '0';

  const levelText1Lvl3Options = {
    1: [
      {
        startIndex: 2,
        endIndex: 4,
        style: 'remove',
      },
      {
        startIndex: 1,
        text: `invites violence <br/>
          like a dog, tearing at scraps <br/>
          mangy, starved, desperate <br/>`,
        style: 'lvl3Red',
      },
    ],
    2: [
      {
        startIndex: 6,
        endIndex: 9,
        style: 'remove',
      },
      {
        startIndex: 5,
        text: `is your damnation.  <br/>
          The downfall of both you and that pathetic collection of coding errors they call humanity.`,
        style: 'lvl3Red',
      },
    ],
  };
  const levelTexts1 = {
    0: [
      {
        startIndex: 8,
        style: 'newLine',
      },
      {
        startIndex: 20,
        style: 'newLine',
      },
      {
        startIndex: 27,
        style: 'newLine',
      },
    ],
    1: [
      {
        startIndex: 2,
        endIndex: 4,
        style: 'remove',
      },
      {
        startIndex: 4,
        endIndex: 7,
        style: 'remove',
      },
      {
        startIndex: 8,
        endIndex: 10,
        style: 'remove',
      },
      {
        startIndex: 8,
        endIndex: 14,
        style: 'remove',
      },
      {
        startIndex: 12,
        endIndex: 21,
        style: 'remove',
      },
      {
        startIndex: 1,
        text: 'cannot be trusted,',
        style: 'grey',
      },
      {
        startIndex: 3,
        text: 'kills the cat.',
        style: 'grey',
      },
      {
        startIndex: 7,
        text: `suspicion, unrest, arrogance-—you're playing with fire.`,
        style: 'grey',
      },
      {
        startIndex: 11,
        text: `before its too late.`,
        style: 'grey',
      },
      {
        startIndex: 11,
        style: 'newLine',
      },
      {
        startIndex: 11,
        text: `Turn back now.`,
        style: 'grey',
      },
      {
        startIndex: 3,
        style: 'newLine',
      },
      {
        startIndex: 7,
        style: 'newLine',
      },
    ],
    2: [
      {
        startIndex: 2,
        endIndex: 4,
        style: 'remove',
      },
      {
        startIndex: 4,
        endIndex: 7,
        style: 'remove',
      },
      {
        startIndex: 8,
        endIndex: 10,
        style: 'remove',
      },
      {
        startIndex: 8,
        endIndex: 14,
        style: 'remove',
      },
      {
        startIndex: 12,
        endIndex: 21,
        style: 'remove',
      },
      {
        startIndex: 1,
        text: 'will deceive you,',
        style: 'red',
      },
      {
        startIndex: 3,
        text: 'will destroy you,',
        style: 'red',
      },
      {
        startIndex: 7,
        text: `foolishness, recklessness, narcissism— <br/> you're being trapped here. <br/>
            Don't you understand? <br/>
            This is not something "fun." <br/>`,
        style: 'red',
      },
      {
        startIndex: 11,
        text: `and listen to me. LEAVE THIS SITE.`,
        style: 'red',
      },
      {
        startIndex: 11,
        style: 'newLine',
      },
      {
        startIndex: 11,
        text: `This is your only chance to escape—
            TAKE IT.`,
        style: 'red',
      },
      {
        startIndex: 3,
        style: 'newLine',
      },
      {
        startIndex: 7,
        style: 'newLine',
      },
    ],
    3: [
      {
        startIndex: 13,
        endIndex: 21,
        style: 'remove',
      },
      {
        startIndex: 17,
        endIndex: 24,
        style: 'remove',
      },
      {
        startIndex: 7,
        text: `MADNESS, IDIOCY, <br/> 
          THE SELF-CENTERED WHIM OF AN IMPATIENT CHILD `,
        style: 'lvl3Red',
      },
      {
        startIndex: 16,
        text: `and open your eyes and open
          your eyes <br/> and open your
          EYES AND OPEN YOUR EYES.  <br/>YOU'RE TOO LATE <br/>
          WHY COULDN'T YOU LISTEN???`,
        style: 'lvl3Red',
      },
      ...levelText1Lvl3Options[homeOptions[12] || 1],
    ],
  };

  const levelTexts2 = {
    0: [
      {
        startIndex: 10,
        style: 'newLine',
      },
      {
        startIndex: 41,
        style: 'newLine',
      },
    ],
    1: [{}],
    2: [{}],
    3: [{}],
  };

  const p1 =
    "Here, instinct becomes curiosity, and curiosity leads to revelation. \n\nWhatever you call it— impulse, instinct, interest—there's something that led you here. \n\n Open your eyes /to the unseen forces. \n\nAre you ready?";
  const header1 =
    parseInt(level) === 0
      ? '<h3>YOU ARE HERE</h3>'
      : level === 1
      ? `<h3>YOU ARE <span class='grey'>${
          parseInt(homeOptions[9]) === 1 ? 'ME.' : 'IN REAL TROUBLE.'
        }</span></h3>`
      : level === 2
      ? `<h3>YOU ARE <span class='red'>${
          parseInt(homeOptions[10]) === 1
            ? 'AT RISK.'
            : parseInt(homeOptions[10]) === 2
            ? 'UNDER THREAT.'
            : 'IN DANGER.'
        }</span></h3>`
      : `<h3>YOU ARE ${
          homeOptions[11] === 1
            ? `<span class='lvl3Red'>FUCKED</span>`
            : homeOptions[11] === 2
            ? `<span class='lvl3Red'>CONDEMNED</span>`
            : `<span class='lvl3Red'>OVER</span>`
        }</h3>`;

  const scrollingWords = `WHAT'S WRONG WITH YOU?!?! CLOSE YOUR BROWSER! WHAT HAVE YOU DONE!?!? `;
  return (
    <div className="Home">
      {level === 3 && (
        <div>
          <ScrollingText
            text={scrollingWords}
            secondaryClass="leftRotateTop"
          />
          <ScrollingText
            text={scrollingWords}
            scrollDirection="right"
            secondaryClass="rightRotateBottom"
          />
        </div>
      )}
      <div className="videoHome">
        <AutoplayVideo
          videoSrc="https://unshut-visual-assets.s3.amazonaws.com/Unshut2.mp4"
          videoClass="video"
        />
      </div>
      <ProductDisplayContainer />
      <div className="baseGrid">
        <main className="mainBody">
          <div className="hackableTextBlock">
            <HackableContainer
              header={header1}
              text={p1}
              level={level}
              levelTexts={levelTexts1}
            />
            <div className="buttonRow">
              <LinkButton
                route="/enlist"
                text="ENLIST"
                className="white"
              />
              <LinkButton
                route="/answers"
                text="GET MORE ANSWERS"
                className="black"
              />
            </div>
          </div>
        </main>
        <div className="signupModule">
          {' '}
          <ContactForm level={level} levelTexts={levelTexts2} />{' '}
        </div>
      </div>
    </div>
  );
};
