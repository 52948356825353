
import './Button.css'

export function LinkButton(props){
    // class should be white or black
    const {route, className, text} = props

    return(
        <a href = {route} className = {className} > {text}</a>
    )
}