

export function addStyle(words, alterText, styleName, type=false){

    if(type){
        if(type==='before'){
            words[alterText?.startIndex] = `<span class=${styleName}>${alterText.text}</span> ${words[alterText?.startIndex]} `
            return words;
        }else if(type==='start'){
            words.push(`<p><span class=${styleName}>${alterText.text}</span> </p>`)
            return words;
        }else{
            console.log('type must be before or start')
        }
    }else{
        words[alterText?.startIndex] = `${words[alterText?.startIndex]} <span class=${styleName}>${alterText.text}</span>`
        return words;
    }

}