import {HackableContainer} from '../../Molucules'
import './ContactFormStyles.css'
import {Link} from 'react-router-dom'
import {IndividualForm} from './IndividualForm'
export function ContactForm(props){

    const {level, levelTexts, enlist} = props;

    levelTexts[0] = [
        {
          startIndex: 10,
          style: 'newLine' 
      },
      {
        startIndex: 41,
        style: 'newLine' 
    },
    ];
    

  
    const text ="We always have been. Watching for the best and brightest recruits. \n\nWe are patient, and aware that these decisions can be life-altering. If you determine you need more time, provide us with a method of contact and we will stay in touch. \n\nUntil then, we will be watching with great interest."
    const header = enlist ? '<h3 class="enlist">WHEN YOU ARE READY</h3>': '<h3 class="enlist">WE ARE HERE, WAITING.</h3>'
    if(!enlist){
    return (
        <div className= 'contactModal'>
            <div>
                 <HackableContainer className= 'hackableText' header = {header} text= {text} level= {level} levelTexts = {levelTexts}/>
            </div>
          <IndividualForm/>

        </div>
      )
    }else{
      return(
        <div className= 'contactModal'>
          <div>
             <HackableContainer className= 'hackableText' header = {header} text= {text} level= {level} levelTexts = {levelTexts}/>

          </div>
          <Link to = '/enlist' >
          <button key='ENLIST' className='button' id = 'enlist' >
        </button>
        </Link>
        </div>
      )
    
    }
  }
    

