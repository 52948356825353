import {HackableTextBlock} from '../../Atoms'
import './HackableTextContainer.css'

export function HackableContainer(props){
    const {header,text, level, levelTexts, headerClass=''} = props
    return(
        <div className = 'hackableTextContainer'>
            <div dangerouslySetInnerHTML={{__html: header}}/>
            <HackableTextBlock text={text} level = {level} levelTexts = {levelTexts}/>
        </div>
    )
}