import React, { useEffect, useState } from "react";
import {
  manageLevelTimeout,
  setDefaultLevels,
  setDefaultRandomText,
} from "../../Functions";
import "./Pages.css";
import { LinkButton, ScrollingText } from "../Atoms";
import { HackableContainer } from "../Molucules";
import { ReactComponent as Logo } from "./Headline-Enlist.svg";
import boxImage from "./unshutBox.jpg";
import "./enlist.css";

export const Enlist = (props) => {
  const { levelTimings, maxLevel, setTransition } = props;

  // either get the altered level for this page if it is 2> lower than the maxLevel,
  // get the level of this page, or 0.
  const [level, setLevel] = useState(() => {
    const savedEnlist = parseInt(localStorage.getItem("Enlist"));
    if (isNaN(savedEnlist)) {
      localStorage.setItem("Enlist", "0");
    }
    if (savedEnlist <= maxLevel - 1) {
      localStorage.setItem("Enlist", String(maxLevel - 1));

      return maxLevel - 1;
    } else {
      return savedEnlist || 0;
    }
  });

  // set the timer. When the timer finishes, it will change the state in local storage and re-render.
  useEffect(() => {
    //TODO change this value when more levels availible
    if (level < 3) {
      const timeout = setTimeout(() => {
        manageLevelTimeout(level, "Enlist", setLevel);
        setTransition(true);
      }, levelTimings[level]);
      return () => clearTimeout(timeout);
    }
  }, [level, setTransition, levelTimings]);

  useEffect(() => {
    const localLevel = localStorage.getItem("Level");
    if (!localLevel) {
      setDefaultLevels();
      setDefaultRandomText();
      setLevel(localStorage.getItem("Level"));
    }
  }, []);

  const p1 =
    "This is a limited beta program for new recruits. As such, our dues have been discounted. With its success, new applicants may find differing costs. <br /><br />Seats are extremely limited but we will add cohorts as space allows.";
  const p2 = "<h3>$100/mo</h3> <h5>Billed monthly for six months</h5>";
  const p3 =
    "<h3>$500</h3> <h5>Single payment. Save $100 over monthly option</h5>";

  const header0 = "";
  const header1 = "<h4>BLACK BOX: SEASON 1: MONTHLY</h4>";
  const header2 = "<h4>BLACK BOX: SEASON 1: FULL EXPERIENCE</h4>";

  const levelTexts1 = {
    1: [
      {
        startIndex: 0,
        endIndex: 25,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `Leave this page and never look back.`,
        style: "greyBefore",
      },
    ],
    2: [
      {
        startIndex: 0,
        endIndex: 25,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `You need to leave. NOW <br/>
          Don't click the products. <br/>
          Don't do this!`,
        style: "lvl3RedBefore",
      },
    ],
    3: [
      {
        startIndex: 0,
        endIndex: 25,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `Can't you see what you've done?!<br/>
          How you're trapped?!<br/>
          Your curiosity has RUINED us!`,
        style: "lvl3RedBefore",
      },
    ],
  };
  const levelTexts2 = {};
  const levelTexts3 = {};

  const answerStyle = {};

  const scrollingWords = `NO NO NO STOP STOP STOP STOP!!! GO BACK LEAVE GO AWAY DON'T DO THIS!!! STOP NOW!!!`;
  return (
    <div className="Enlist">
      <div className="baseGrid">
        <div className="mainBody">
          <div className="headerHolder">
            {" "}
            <Logo> </Logo>{" "}
          </div>

          {level === 3 && (
            <div>
              <ScrollingText
                text={scrollingWords}
                secondaryClass="leftRotateTop"
              />
              <ScrollingText
                text={scrollingWords}
                scrollDirection="right"
                secondaryClass="rightRotateBottom"
              />
            </div>
          )}
          <div className="hackableTextBlock">
            <HackableContainer
              key={header0}
              header={header0}
              text={p1}
              level={level}
              levelTexts={levelTexts1}
            />
          </div>
          <div className="saleItemBlock">
            <div className="saleItemDescription">
              <div
                key={header1}
                style={answerStyle}
                className="hackableTextBlock"
              >
                <HackableContainer
                  key={header1}
                  header={header1}
                  text={p2}
                  level={level}
                  levelTexts={levelTexts2}
                />
              </div>
            </div>
            <div className="saleItemButton">
              <LinkButton
                className="white"
                text="SELECT"
                route="https://unshut.me/enlist/p/black-box-subscription"
              />
            </div>
          </div>

          <div className="saleItemBlock">
            <div className="saleItemDescription">
              <div
                key={header1}
                style={answerStyle}
                className="hackableTextBlock"
              >
                <HackableContainer
                  key={header2}
                  header={header2}
                  text={p3}
                  level={level}
                  levelTexts={levelTexts3}
                />
              </div>
            </div>
            <div className="saleItemButton">
              <LinkButton
                className="white"
                text="SELECT"
                route="https://unshut.me/enlist/p/p/black-box-season-1"
              />
            </div>
          </div>
        </div>
        <div className="enlistBoxDisplay">
          {" "}
          <img src={boxImage} />
        </div>
      </div>
    </div>
  );
};
