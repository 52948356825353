import './ProductDisplayContainer.css';
import ruinProductCover from '../../../images/ruin-product-cover.png';
import singlarityProductCover from '../../../images/singlarity-product-cover.png';
import blackBoxProductCover from '../../../images/black-box-product-cover-white.png';

const products = [
  {
    id: 1,
    name: 'THE RUIN',
    teaser:
      'An immersive digital theatre horror experience you may never recover from...',
    img: ruinProductCover,
    cta: 'RUIN YOURSELF',
    href: 'https://unshut.me/the-ruin',
  },
  {
    id: 2,
    name: 'SINGLARITY',
    teaser:
      'Improve your dating skills with you very own AI dating coach... or join the resistance against the robots.',
    img: singlarityProductCover,
    cta: 'FIGHT THE MACHINES',
    href: 'https://unshut.me/singlarity',
  },
  {
    id: 3,
    name: 'BLACK BOX (COMING SOON)',
    teaser:
      'Our most immersive experience combines live performances, VR, AR, nootropics, AI, and more to transform your life into a dark Sci-Fi adventure.',
    img: blackBoxProductCover,
    cta: 'PRE-ORDER NOW',
    href: 'https://unshut.me/black-box',
  },
];

const ProductCard = ({ product }) => {
  const handleClick = () => {
    window.open(product.href, '_blank', 'noopener,noreferrer');
  };

  return (
    <article className="product-card">
      <div className="card-contents">
        <h2>{product.name}</h2>

        <a href={product.href} className="img-wrapper" alt={product.name}>
          <img src={product.img} alt={product.name} />
        </a>
        <p>{product.teaser}</p>
        <button
          onClick={handleClick}
          aria-label={`Learn more about ${product.name}`}
        >
          {product.cta}
        </button>
      </div>
    </article>
  );
};

export const ProductDisplayContainer = () => {
  return (
    <div className="product-display-container">
      {products.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
};
