import React, { useEffect, useState } from "react";
import { manageLevelTimeout } from "../../Functions";
import "./Pages.css";
import axios from "axios";

export const Validate = (props) => {
  const { levelTimings, maxLevel, setTransition, token } = props;

  let message = `We got everything we needed... Now you can't escape.`;
  //const messageError = `Something odd happened... Try again.`;

  const dbUrl = "https://1twtb1eich.execute-api.us-east-1.amazonaws.com/prod";

  const ax = axios.create({
    baseURL: dbUrl,
    headers: {
      common: {
        x_api_key: "ya",
      },
    },
  });

  // either get the altered level for this page if it is 2> lower than the maxLevel,
  // get the level of this page, or 0.
  const [level, setLevel] = useState(() => {
    const savedMailing = parseInt(localStorage.getItem("Mailing"));
    if (isNaN(savedMailing)) {
      localStorage.setItem("Mailing", "0");
    }
    if (savedMailing <= maxLevel - 1) {
      localStorage.setItem("Mailing", String(maxLevel - 1));
      return maxLevel - 1;
    } else {
      return savedMailing || 0;
    }
  });

  const validateUser = () => {
    ax.post(`/user/validate/${token}`, null)
      .then((res) => {
        
        console.log(res);
      })
      .catch((err) => {
        message = `Try again`;
      });
  };

  // set the timer. When the timer finishes, it will change the state in local storage and re-render.
  useEffect(() => {
    //TODO change this value when more levels are availible
    if (level < 2) {
      const interval = setTimeout(() => {
        manageLevelTimeout(level, "Mailing", setLevel);
        setTransition(true);
      }, levelTimings[level]);
      return () => clearTimeout(interval);
    }
    validateUser();
  }, [level, setTransition, levelTimings]);

  return (
    <div className="baseGrid">
      <div className="mainBody">
        <div className="headerHolder"></div>
        {message}
      </div>
    </div>
  );
};
