import {
  Home,
  Answers,
  Mailing,
  Enlist,
  Error404,
  Validate,
} from '../../Pages';
import { Header, Footer } from '../../Molucules';
import { useEffect, useState } from 'react';
import {
  setDefaultLevels,
  changeStateToAfterTimeout,
  setDefaultRandomText,
  getRandomInt,
} from '../../../Functions';
import gif1 from './01.gif';
import gif2 from './02.gif';
import gif3 from './03.gif';
import { addFBTrackingPixel } from '../../../Functions';

// try to install the font using import link?
// import url("https://use.typekit.net/yqc6tdj.css");
import './PageTemplate.css';

export function PageTemplate(props) {
  const { currentTab } = props;
  const token = props.match.params.token;

  const [level, setLevel] = useState(localStorage?.getItem('Level') || '');
  const [transition, setTransition] = useState(false);
  const randomGif = getRandomInt(3);

  let glitchGif;

  switch (randomGif) {
    case 1:
      glitchGif = gif1;
      break;
    case 2:
      glitchGif = gif2;
      break;
    case 3:
      glitchGif = gif3;
      break;
    default:
      console.log('something did not initialize properly');
  }

  // set/get information in local storage.
  useEffect(async () => {
    const localLevel = localStorage.getItem('Level');
    if (!localLevel) {
      setDefaultLevels();
      setDefaultRandomText();
      await setLevel(0);
    } else {
      await setLevel(parseInt(localLevel));
    }

    addFBTrackingPixel();
  }, []);

  // controls how long the gif lasts
  useEffect(() => {
    if (transition === true) {
      changeStateToAfterTimeout(1000, false, setTransition);
    }
  }, [transition]);

  return (
    <div
      className="pageTemplate"
      style={{
        backgroundSize: 'cover',
        backgroundImage: transition === true ? `url(${glitchGif})` : '',
      }}
    >
      <Header />
      <main>
        {renderPageSwitch(currentTab, level, setTransition, token)}
      </main>
      <Footer />
    </div>
  );
}

function renderPageSwitch(tabName, level, setTransition, token) {
  //controls when levels change.
  const levelTimings = {
    0: 15000,
    1: 20000,
    2: 20000,
  };
  //transition in set in pages below/
  switch (tabName) {
    case 'HOME':
      return (
        <Home
          maxLevel={parseInt(level)}
          levelTimings={levelTimings}
          setTransition={setTransition}
        />
      );
    case 'ANSWERS':
      return (
        <Answers
          maxLevel={parseInt(level)}
          levelTimings={levelTimings}
          setTransition={setTransition}
        />
      );
    case 'MAILING':
      return (
        <Mailing
          maxLevel={parseInt(level)}
          levelTimings={levelTimings}
          setTransition={setTransition}
        />
      );
    case 'ENLIST':
      return (
        <Enlist
          maxLevel={parseInt(level)}
          levelTimings={levelTimings}
          setTransition={setTransition}
        />
      );
    case 'VALIDATE':
      return (
        <Validate
          maxLevel={parseInt(level)}
          levelTimings={levelTimings}
          setTransition={setTransition}
          token={token}
        />
      );
    case '404':
      return <Error404 />;
    default:
      return <p>YOU DO NOT BELONG HERE, CHILD.</p>;
  }
}
