import './Footer.css'
import {useState, useEffect} from 'react';
import axios from 'axios';
import {ReactComponent as Barcode} from './Barcode.svg'

export function Footer(){

    const [ip, setIP] = useState('');

    useEffect(() => {
         async function getIpData() {
            const res = await axios.get('https://geolocation-db.com/json/')
            setIP(res.data);
        }
        getIpData();
    }, [])
return(
    <div>
        <div className='footerSeparator' />
        <div id='footer'>
    
            <div id= 'barcodeContainer'>
                <div className='barcode'><Barcode> </Barcode></div>
            </div>
            <div id='footerText'>
                <p>THIS WEBSITE IS APPROVED FOR USE BY UNSHUT APPLICANTS ONLY. <br/>
                    UNAUTHORIZED ACCESS OF THIS WEBSITE WILL BE TRACKED AND MONITORED. <br/><br/>
                    YOUR IP ADDRESS:  {ip['IPv4']} WE SEE YOU IN: {ip['city']}  TRACKING NUMBER: 0.183.29920.2934.10947.56352999</p>
            </div>
        </div>
    </div>
)
}