
import './HackableText.css';
import {addStyle} from '../../../Functions'
export function HackableTextBlock(props){
    const {level, text}= props;
    
    //hash with keys of text, startIndex and style. Optionally has endIndex
    const levelTexts = props?.levelTexts || 0;

    let words = text?.split(' ');
    

    const alterTextActions = levelTexts[parseInt(level)] || 0;

    if(alterTextActions?.length > 0){
        for(const alterText of alterTextActions){
            // add text after if no end index
               const style = alterText?.style;

              if(style){
                  switch(style){
                    case 'remove':
                        words.splice( alterText?.startIndex, alterText?.endIndex - alterText?.startIndex )
                        break;
                    case 'newline':
                        words[alterText?.startIndex] = ` ${words[alterText?.startIndex]} <br><br>`;
                        break;
                    case 'grey':
                        words = addStyle(words, alterText, 'grey');
                        break;
                    case 'greyBefore':
                        words = addStyle(words, alterText, 'grey', 'before');
                        break;
                    case 'greyStart':
                        words = addStyle(words, alterText, 'grey', 'start')
                        break;
                    case 'red':
                        words = addStyle(words, alterText, 'red');
                        break;
                    case 'redBefore':
                        words = addStyle(words, alterText, 'red', 'before');
                        break;
                    case 'redStart':
                        words = addStyle(words, alterText, 'red', 'start');
                        break;
                    case 'lvl3Red':
                        words = addStyle(words, alterText, 'lvl3Red');
                        break;
                    case 'lvl3RedBefore':
                        words = addStyle(words, alterText, 'lvl3Red', 'before');
                        break;
                    case 'lvl3RedStart':
                        words = addStyle(words, alterText, 'lvl3Red', 'start');
                        break;
                    default:
                        break;
                  }
            }
        }
    }

    
    words.push('</p>');
    words.unshift('<p>')
    const htmlString = words.join(' ');

    return(
        <div dangerouslySetInnerHTML={{__html: htmlString}} />
    )

}