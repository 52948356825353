
import React from 'react';
export  function AutoplayVideo(props){
const {videoSrc, videoClass} = props;

return(
    <video className= {videoClass} autoPlay playsInline muted loop>
        <source  src={videoSrc} type="video/mp4"/>
    </video>
)
}