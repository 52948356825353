import {getRandomInt} from '../Functions'

export function setDefaultRandomText(){

    //ANSWER
    const answer1 = getRandomInt(3);
    const answer2 = getRandomInt(2);
    //level2
    const answer3 = getRandomInt(3);
    const answer4 = getRandomInt(2);
    //level3
    const answer5 = getRandomInt(3);
    const answer6 = getRandomInt(2);

    //HOME
    const home1 = getRandomInt(2);
    const home2= getRandomInt(2);
    const home3 = getRandomInt(2);
    const home4 =getRandomInt(2);
    const home5 = getRandomInt(2);
    const home6=getRandomInt(2);
    const home7= getRandomInt(2);
    const home8=getRandomInt(2);
    const home9=getRandomInt(2);
    //homeHeaders
    const home10= getRandomInt(2);
    const home11=getRandomInt(3);
    const home12=getRandomInt(3);
    const home13 = getRandomInt(2);

    
    localStorage.setItem("answerText",`${answer1},${answer2},${answer3},${answer4},${answer5},${answer6}`);
    localStorage.setItem("homeText", `${home1},${home2},${home3},${home4},${home5},${home6},${home7},${home8},${home9},${home10},${home11},${home12},${home13}`);


}