import './Navbar.css';

export function Navbar() {
  //array of tabs & the history to push with them. Current tab is the active tab

  return (
    <div className="navBar">
      <a className="navTabText" href="https://unshut.me/the-ruin">
        <button key="ANSWERS" className="navTab" id="enlist">
          THE RUIN
        </button>
      </a>
      <a className="navTabText" href="https://unshut.me/singlarity">
        <button key="ANSWERS" className="navTab" id="enlist">
          SINGLARITY
        </button>
      </a>

      <a className="navTabText" href="https://unshut.me/black-box">
        <button key="ANSWERS" className="navTab" id="enlist">
          BLACK BOX
        </button>
      </a>

      <a className="navTabText" href="https://unshut.me/contact">
        <button key="ANSWERS" className="navTab" id="enlist">
          CONTACT
        </button>
      </a>

      <a className="navTabText" href="https://unshut.me/about-us">
        <button key="ANSWERS" className="navTab" id="enlist">
          ABOUT US
        </button>
      </a>
    </div>
  );
}
