import { Navbar, MobileNavMenu } from '../../Atoms';
import './header.css';
import { ReactComponent as MobileNav } from './mobileNav.svg';
import { ReactComponent as CloseButton } from './closeButton.svg';
import { useState } from 'react';

export function Header() {
  const [mobileNavActive, setIsMobileNavActive] = useState(false);

  const handleMobileClick = async () => {
    setIsMobileNavActive(!mobileNavActive);
  };
  return (
    <>
      <div className="header">
        <div className="logo">
          <img src="/Images/Unshut_Logo_Horiz.png" alt="UNSHUT Logo" />
        </div>

        <div className="mobileNav">
          <div className="mobileNavHolder" onClick={handleMobileClick}>
            {mobileNavActive ? <CloseButton /> : <MobileNav />}
          </div>
        </div>
        <div className="navBar">
          <Navbar />
        </div>
      </div>

      <div className="navSeparator" />

      <MobileNavMenu
        setIsMobileNavActive={setIsMobileNavActive}
        classToUse={mobileNavActive ? 'navArea.menu-active' : 'navArea'}
      />
    </>
  );
}
