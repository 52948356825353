import "./App.css";
import { Route, Switch } from "react-router-dom";
import { PageTemplate } from "./Components";

function App() {

 
  //TODO please replace with HOC once figure out how to use in this instance. Currently is spaghetti.
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(props) => <PageTemplate {...props} currentTab="HOME" />}
      />
      <Route
        exact
        path="/answers"
        render={(props) => <PageTemplate {...props} currentTab="ANSWERS" />}
      />
      <Route
        exact
        path="/mailing"
        render={(props) => <PageTemplate {...props} currentTab="MAILING" />}
      />
      <Route
        exact
        path="/enlist"
        render={(props) => <PageTemplate {...props} currentTab="ENLIST" />}
      />
      <Route
        exact
        path="/validate/:token"
        render={(props) => <PageTemplate {...props} currentTab="VALIDATE" />}
      />
      <Route render={(props) => <PageTemplate {...props} currentTab="404" />} />
    </Switch>
  );
}

export default App;
