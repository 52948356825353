import React, {useEffect, useState} from 'react';
import {setDefaultLevels, manageLevelTimeout} from '../../Functions';
import {HackableContainer} from '../Molucules'
import {ContactForm, IndividualForm} from '../Organism';
import {Link} from 'react-router-dom'
import {ScrollingText} from '../Atoms'
import './Pages.css'
import {ReactComponent as Logo} from './Headline-MailingList.svg'


export const Mailing = (props) => {

    const {levelTimings, maxLevel, setTransition} = props;

         // either get the altered level for this page if it is 2> lower than the maxLevel,
// get the level of this page, or 0.
    const [level, setLevel] = useState(() =>{
        const savedMailing = parseInt(localStorage.getItem("Mailing"));
        if(isNaN(savedMailing)){
          localStorage.setItem("Mailing", "0");
        }
        if( savedMailing <= maxLevel-1){
          localStorage.setItem("Mailing", String(maxLevel-1));
          return maxLevel-1
        }else{
        return savedMailing || 0
        }
      });

       // set the timer. When the timer finishes, it will change the state in local storage and re-render. 
    useEffect(()=>{
        //TODO change this value when more levels are availible
        if(level < 3){
          const interval = setTimeout(() => {
            manageLevelTimeout(level, 'Mailing', setLevel);
            setTransition(true);
          }, levelTimings[level]);
          return() => clearTimeout(interval);
        }
      },[level, setTransition, levelTimings]);

      const headerForm =  'WHEN YOU ARE READY'

      const p1 = 'Curious about what we do, but not ready to commit? Fill out this contact form to stay up-to-date on our organization’s latest developments.'

      const levelTexts1 = {
        0:[{}],
        1: [{
          startIndex:0,
          endIndex:23,
          style: 'remove'
        },
        {  
          text: `Follow your instinct. Do you feel that pit of your stomach? Leave this site and never look back.`,
          style: 'greyStart'
        }
      ],

        2:[{
          startIndex:0,
          endIndex:23,
          style: 'remove'
        },
        {  
          text: `Don't give them anything. They already know too much. This is your last chance.`,
          style: 'redStart'
        }],
        3:[{
          startIndex:0,
          endIndex:23,
          style: 'remove'
        },
        {  
          text: `ONCE UPON A TIME THERE WAS A WEBSITE THAT SEEMED TO PROMISE A FUN EXPERIENCE UNLIKE ANYTHING BEFORE.
          SO YOU DECIDED TO GIVE IT A TRY, ONLY TO FIND OUT THAT IT WASN'T A FAIRY TALE AND THAT IT WAS REAL AND THERE WAS NO WAY YOU COULD RETURN TO YOUR NORMAL LIFE AND YOU LIVED WITH REGRET FOR ETERNITY. THE END.`,
          style: 'lvl3RedStart'
        }],
      };

      const levelTexts2 = {
        0:[
          {
            startIndex: 10,
            style: 'newLine' 
        },
        {
          startIndex: 41,
          style: 'newLine' 
      },
      ],
        1:[   {
          startIndex: 10,
          style: 'newLine' 
      },
      {
        startIndex: 41,
        style: 'newLine' 
    },],
        2:[{}],
        3:[{}]
      };

      const scrollingWords = `DON'T GIVE THEM YOUR INFO! WHAT'S WRONG WITH YOU??! STOP STOP STOP!!!`
      return(
        <div className = 'baseGrid'>
            {level===3 && 
                <div>
                <ScrollingText text={scrollingWords} secondaryClass='leftRotateTop'/>
                  <ScrollingText text={scrollingWords} scrollDirection='right' secondaryClass='rightRotateBottom'/>
                </div>
              }
            <div className = 'mainBody'>
                <div className = 'headerHolder'> <Logo> </Logo> </div>
                <HackableContainer header='' text={p1} level={level} levelTexts={levelTexts1}/>
                <IndividualForm/>
            </div>

            
            
            <div className='signupModule'>
                <h3>{headerForm}</h3>
                <p>You will know when the time is right to contact us. As soon as you are, we will be ready.</p>
                <Link to = '/enlist' >
                  <button key='ENLIST' className='button' id = 'enlist' >ENLIST</button>
                </Link>
            </div>
        </div>

      )

}