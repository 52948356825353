import { Formik, Form, Field } from "formik";
import { ContactFormSchema } from "../../../Schemas";
import{useState} from 'react';
import axios from "axios";

export function IndividualForm() {
  const dbUrl = "https://1twtb1eich.execute-api.us-east-1.amazonaws.com/prod";

  const [success, setSuccess] = useState(false);
  const [processable, setProcessable] = useState(true);

  const ax = axios.create({
    baseURL: dbUrl,
    headers: {
      common: {
        x_api_key: "ya",
      },
    },
  });
  return (
    <Formik
      initialValues={{
        fullname: "",
        greatestFear: "",
        email: "",
      }}
      validationSchema={ContactFormSchema}
      onSubmit={async(values) => {
        try {
          if(processable){
            await setProcessable(false);
          ax.post(`${dbUrl}/user/subscribe`, values).then((response) => {
              setSuccess(true);
          });
        }
        } catch (err) {
          setProcessable(true);
          console.log(err);
        }
      }}
    >
      {({ errors, touched }) => (
        <Form>
          <div>
            <Field
              className="textInput"
              placeholder="verify your name"
              size="40"
              name="fullname"
              type="text"
            />
            {errors.name && touched.name ? (
              <div className="error">{errors.name}</div>
            ) : null}
           
          </div>

          <div>
            <Field
              className="textInput"
              placeholder="what scares you?"
              size="40"
              name="greatestFear"
              type="text"
            />
          </div>

          <div>
            <Field
              className="textInput"
              placeholder="enter an email address"
              size="27"
              name="email"
              type="text"
            />
            {errors.email && touched.email ? (
              <div className="error">{errors.email}</div>
            ) : null}
            <button className="button" type="submit">
              SUBMIT
            </button>
            {success ? (<div className='success'>Submission successful. See you soon.</div>) : null}
          </div>
        </Form>
      )}
    </Formik>
  );
}
