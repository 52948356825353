import './404.css'
import {AutoplayVideo} from '../Atoms'
import video from './img/moshed.mp4'
export const Error404 = () => {
    return(
        <div className='mainContainer' style={{backgroundImage: `url(${video})`}}>
            <div className="vidContainer">
                <AutoplayVideo videoSrc="img/moshed.mp4" className="myVideo"/>
             </div>
            <div className="container">
                <h2 className="content__title">YOU SHOULD NOT BE HERE</h2>
            </div>
        </div>
    )
}