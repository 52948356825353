import './MobileNavMenu.css';
const cls = 'navArea';

export function MobileNavMenu(props) {
  const { classToUse, setIsMobileNavActive } = props;

  //array of tabs & the history to push with them. Current tab is the active tab
  const handleClick = async () => {
    await setIsMobileNavActive(false);
  };

  return (
    <div className={` mobileNavContainer ${classToUse}`}>
      {/* <Link className="mobileNavLink" to="/" onClick={handleClick}>
        <div key="HOME" className="mobileNavItem" id="home2">
          HOME
        </div>
      </Link>

      <Link to="/answers" className="mobileNavLink" onClick={handleClick}>
        <div key="ANSWERS" className="mobileNavItem" id="answer2">
          ANSWERS
        </div>
      </Link>

      <Link to="/mailing" className="mobileNavLink" onClick={handleClick}>
        <div key="MAILING" className="mobileNavItem" id="enlist">
          MAILING LIST
        </div>
      </Link>

      <Link to="/enlist" className="mobileNavLink" onClick={handleClick}>
        <div key="ENLIST" className="mobileNavItem" id="enlist">
          ENLIST
        </div>
      </Link> */}

      <a href="https://theruin.unshut.me/">
        <button className="mobileNavLink">THE RUIN</button>
      </a>
      <a href="https://singlarity.unshut.me/">
        <button className="mobileNavLink">SINGLARITY</button>
      </a>
      <a href="https://blackbox.unshut.me/">
        <button className="mobileNavLink">BLACK BOX</button>
      </a>
      <a href="https://unshut.me/contact">
        <button className="mobileNavLink">CONTACT</button>
      </a>
      <a href="https://unshut.me/about-us">
        <button className="mobileNavLink">ABOUT US</button>
      </a>
    </div>
  );
}
