
import './scrolling.css';

export function ScrollingText(props){

    const{text, secondaryClass, scrollDirection='left'} = props;
    return(
            <div className={`${secondaryClass}`}> 
                <div className='breakers'> <h4> </h4></div>
                <div className='breakers2'> <h4> </h4></div>
                <div className='breakers3'> <h4> </h4></div>
                <div className='breakers4'> <h4> </h4></div>
                <div className='scroll'>
                    
                    <h4 className= {scrollDirection==='left' ? `scrollText` : 'scrollText2'}>{text}</h4>
                </div>
                
            </div>
    
    )
}