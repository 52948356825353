

export async function manageLevelTimeout(level, page, stateFunction){
    const newLevel = parseInt(level)+1;
    await stateFunction(newLevel);
    localStorage.setItem(page, String(newLevel));
    if(parseInt(localStorage.getItem('Level')) < newLevel){
        localStorage.setItem('Level', String(newLevel));
    }

}