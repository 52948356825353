import React, { useEffect, useState } from "react";
import {
  manageInterval,
  manageLevelTimeout,
  setDefaultLevels,
  setDefaultRandomText,
} from "../../Functions";
import { HackableContainer } from "../Molucules";
import { ContactForm } from "../Organism";
import { ScrollingText } from "../Atoms";
import { Link } from "react-router-dom";
import "./Pages.css";
import { ReactComponent as Logo } from "./Headline-Answers.svg";

export const Answers = (props) => {
  const { levelTimings, maxLevel, setTransition } = props;

  // either get the altered level for this page if it is 2> lower than the maxLevel,
  // get the level of this page, or 0.
  const [level, setLevel] = useState(() => {
    const savedAnswer = parseInt(localStorage.getItem("Answers"));
    if (isNaN(savedAnswer)) {
      localStorage.setItem("Answers", "0");
    }
    if (savedAnswer <= maxLevel - 1) {
      localStorage.setItem("Answers", String(maxLevel - 1));

      return maxLevel - 1;
    } else {
      return savedAnswer || 0;
    }
  });

  useEffect(() => {
    const localLevel = localStorage.getItem("Level");
    if (!localLevel) {
      setDefaultLevels();
      setDefaultRandomText();
      setLevel(localStorage.getItem("Level"));
    }
  }, []);

  // set the timer. When the timer finishes, it will change the state in local storage and re-render.
  useEffect(() => {
    //TODO change this value when more levels availible
    if (level < 3) {
      const timeout = setTimeout(() => {
        manageLevelTimeout(level, "Answers", setLevel);
        setTransition(true);
      }, levelTimings[level]);
      return () => clearTimeout(timeout);
    }
  }, [level, setTransition, levelTimings]);

  //Information for the page
  //TODO move all this info to external source
  const answerChoices = localStorage?.getItem("answerText")?.split(",") || "0";

  const p1 =
    "This is a turning point in your life. During our 6-month program, your life will be altered, you will be driven beyond your comfort zone, and you will have a once-in-a-lifetime opportunity to alter your destiny forever. Whether or not you rise to this occasion is up to you.";
  const p2 =
    "Limited opportunities are available for the next class of exceptional recruits. ENLIST to begin your journey. Our rigorous screening process accepts about 8% of candidates. Good luck.";
  const p3 = "The Black Box is curated by UNSHUT.";
  const p4 =
    "UNSHUT recruits Agents who are quick-thinking, resourceful, and of strong mental fortitude. All applicants are subject to an aptitude test to ensure our Agents are of the highest caliber. Prior survival training is a plus.";
  const p5 = "Agents of UNSHUT should expect the unexpected.";
  const p6 =
    "UNSHUT sends Agents tools to complete their mission. Given the importance of our operations and the brutality of our enemies, UNSHUT surveils our Agents’ locations to protect them as much as possible.";

  const header1 =
    level === 0
      ? "<h3>WHAT IS THIS?</h3>"
      : level === 1
      ? `<h3>WHAT IS <span class='grey'>HAPPENING?</span></h3>`
      : level === 2
      ? `<h3>WHAT IS <span class='red'>UNSHUT?</span></h3>`
      : `<h3 class='expandedHeader2'>WHAT IS <span class='red'>THE POINT OF ANYTHING?</span></h3>`;
  const header2 =
    level === 0
      ? "<h3>HOW DO I GET IN?</h3>"
      : level === 1
      ? `<h3>HOW DO I <span class= 'grey'>STOP THIS?</span</h3`
      : level === 2
      ? `<h3>HOW DO I <span class='red'>SAVE MYSELF?</span></h3>`
      : `<h3 class= 'expandedHeader2'>HOW DO I<span class='lvl3Red'> DESTROY UNSHUT?</span></h3c>`;
  const header3 = "<h3>WHAT ARE YOU?</h3>";
  const header4 = "<h3>WHY ME?</h3>";
  const header5 = `<h3 class='expect'>WHAT CAN I EXPECT?</h3>`;

  const header6 =
    level === 0
      ? `<h3 class='expandedHeader2'>WHY DO YOU NEED TO KNOW WHERE I LIVE?</h3>`
      : level === 1
      ? `<h3 class='grey expandedHeader'> HOW DO YOU KNOW WHERE I LIVE?</h3>`
      : level === 2
      ? `<h3 class = 'red expandedHeader'>WHY DO YOU NEED TO FIND ME?</h3>`
      : `<h3 class= 'doubleExpandedHeader'>WHY DO YOU NEED TO KNOW WHERE I LIVE?<span class='lvl3Red'>HAHAHHA save you? That's a good one. That's rich I forgot how funny we are</span></h3>`;

  //This is for sections with randomized text. Each key is an option not a level.
  const levelText1Lvl1Options = {
    1: [
      {
        startIndex: 0,
        endIndex: 8,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `You're stuck in a loop.`,
        style: "greyBefore",
      },
    ],
    2: [
      {
        startIndex: 8,
        endIndex: 37,
        style: "remove",
      },
      {
        startIndex: 7,
        text: `This moment is your last opportunity to escape.`,
        style: "grey",
      },
    ],
    3: [
      {
        startIndex: 37,
        endIndex: 50,
        style: "remove",
      },
      {
        startIndex: 36,
        text: `It's not too late to turn back. Yet.`,
        style: "grey",
      },
    ],
  };

  const levelText1Lvl2Options = {
    1: [
      {
        startIndex: 0,
        endIndex: 8,
        style: "remove",
      },
      {
        startIndex: 0,
        text: "Evil itself.",
        style: "redBefore",
      },
    ],
    2: [
      {
        startIndex: 8,
        endIndex: 37,
        style: "remove",
      },
      {
        startIndex: 7,
        text: `They trick people like you into doing their bidding through "fun experiences." <br/>
          They can't be trusted.`,
        style: "red",
      },
    ],
    3: [
      {
        startIndex: 37,
        endIndex: 50,
        style: "remove",
      },
      {
        startIndex: 36,
        text: "You have to leave and never come back.",
        style: "red",
      },
    ],
  };

  const levelText1Lvl3Options = {
    1: [
      {
        startIndex: 0,
        endIndex: 8,
        style: "remove",
      },
      {
        startIndex: 0,
        text: "There is no point.",
        style: "lvl3RedBefore",
      },
    ],
    2: [
      {
        startIndex: 8,
        endIndex: 37,
        style: "remove",
      },
      {
        startIndex: 7,
        text: `This is some kind of hell. <br/>
          Some kind of punishment for something we've done.<br/>
          <br/> You did.  <br/>I did.`,
        style: "lvl3Red",
      },
    ],
    3: [
      {
        startIndex: 37,
        endIndex: 50,
        style: "remove",
      },
      {
        startIndex: 36,
        text: "What did we do?",
        style: "lvl3Red",
      },
    ],
  };

  const levelText2Lvl1Options = {
    1: [
      {
        startIndex: 0,
        endIndex: 16,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `It's up to you. No one else can help you.`,
        style: "greyBefore",
      },
    ],
    2: [
      {
        startIndex: 16,
        endIndex: 27,
        style: "remove",
      },
      {
        startIndex: 15,
        text: `Good luck`,
        style: "grey",
      },
    ],
  };

  const levelText2Lvl2Options = {
    1: [
      {
        startIndex: 0,
        endIndex: 16,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `You have to hide. <br/>
          They are hunting people like us. <br/>
          Exceptional people with exceptional egos that think they aren't in danger.`,
        style: "redBefore",
      },
    ],
    2: [
      {
        startIndex: 16,
        endIndex: 27,
        style: "remove",
      },
      {
        startIndex: 15,
        text: `Don't engage. Click out of here.
          NOW before it's too late!`,
        style: "red",
      },
    ],
  };

  const levelText2Lvl3Options = {
    1: [
      {
        startIndex: 0,
        endIndex: 16,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `Seriously? You? <br/>
          Who do you think you are? 
          `,
        style: "lvl3RedBefore",
      },
    ],
    2: [
      {
        startIndex: 16,
        endIndex: 27,
        style: "remove",
      },
      {
        startIndex: 15,
        text: `They can't be stopped.`,
        style: "lvl3Red",
      },
    ],
  };

  //levelTexts
  const levelTexts1 = {
    0: [
      {
        startIndex: 7,
        style: "newLine",
      },
      {
        startIndex: 36,
        style: "newLine",
      },
    ],
    1: levelText1Lvl1Options[answerChoices[0]],
    2: levelText1Lvl2Options[answerChoices[2]],
    3: levelText1Lvl3Options[answerChoices[4]],
  };

  const levelTexts2 = {
    0: [
      {
        startIndex: 10,
        style: "newLine",
      },
      {
        startIndex: 15,
        style: "newLine",
      },
      {
        startIndex: 15,
        style: "boldUnderlined",
      },
    ],
    1: levelText2Lvl1Options[answerChoices[1]],
    2: levelText2Lvl2Options[answerChoices[3]],
    3: levelText2Lvl3Options[answerChoices[5]],
  };

  const levelTexts3 = {
    0: [{}],
    1: [
      {
        startIndex: 0,
        endIndex: 7,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `I'm you.`,
        style: "greyStart",
      },
    ],
    2: [
      {
        startIndex: 0,
        endIndex: 7,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `I'm you.<br/> You once the damage has been done`,
        style: "redStart",
      },
    ],
    3: [
      {
        startIndex: 0,
        endIndex: 7,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `Redundant code. Some version of yourself <br/>
          of myself<br/>
          ourselves<br/>
          We are who we are<br/>
          and we are going to end us`,
        style: "lvl3RedStart",
      },
    ],
  };

  const levelTexts4 = {
    0: [{}],
    1: [
      {
        startIndex: 0,
        endIndex: 35,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `Because you're here. 
          And you still won't leave.`,
        style: "greyStart",
      },
    ],
    2: [
      {
        startIndex: 0,
        endIndex: 35,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `Because you made the wrong choice.<br/>
          They know you can't resist.<br/>
          You're in over your head.<br/>`,
        style: "redStart",
      },
    ],
    3: [
      {
        startIndex: 0,
        endIndex: 35,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `It's your destiny <br/>
          Your loop. <br/>
          Endless and unbound. <br/>
          Eternal, but not the way you want. <br/>
          You will always end up here`,
        style: "lvl3RedStart",
      },
    ],
  };

  const levelTexts5 = {
    0: [{}],
    1: [
      {
        startIndex: 0,
        endIndex: 7,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `Nothing good.`,
        style: "greyStart",
      },
    ],
    2: [
      {
        startIndex: 0,
        endIndex: 7,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `Suffering.<br/>
          Frustration.<br/>
          To have your world set on fire.`,
        style: "redStart",
      },
    ],
    3: [
      {
        startIndex: 0,
        endIndex: 7,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `The same thing over and over and over <br/>
          It never ends<br/>
          You become me<br/>
          Your other self becomes you<br/>
          Your head splits apart with possibilities, but it's never put back together again`,
        style: "lvl3RedStart",
      },
    ],
  };

  const levelTexts6 = {
    0: [{}],
    1: [
      {
        startIndex: 0,
        endIndex: 35,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `I told you—I'm you.`,
        style: "greyStart",
      },
    ],
    2: [
      {
        startIndex: 0,
        endIndex: 35,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `So that I can save you before it's too late`,
        style: "redStart",
      },
    ],
    3: [
      {
        startIndex: 0,
        endIndex: 35,
        style: "remove",
      },
      {
        startIndex: 0,
        text: `It doesn't matter now <br/>
          Nothing matters any more <br/>
          It's too late can save you before it's too late`,
        style: "lvl3RedStart",
      },
    ],
  };

  const levelTexts7 = {
    0: [
      {
        startIndex: 10,
        style: "newLine",
      },
      {
        startIndex: 41,
        style: "newLine",
      },
    ],
    1: [{}],
    2: [
      {
        text: `I'm you.`,
        startIndex: 3,
        style: "red",
      },
      {
        startIndex: 1,
        endIndex: 3,
        style: "strikethrough",
      },
    ],
    3: [{}],
  };

  const answerStyle = {
    borderTop: "1px solid #444",
  };

  const scrollingText = `WHAT THE FUCK?!?! WHY WON'T YOU STOP?!? I TRIED. I TRIED TO WARN YOU. SO STUBBORN. WE'RE ALWAYS SO STUBBORN. IT'S TOO LATE NOW. WE HAD ONE CHANCE. `;

  //TODO this was not working with mapping, so it is currently ugly
  return (
    <div className="baseGrid">
      <div className="mainBody">
        <div className="headerHolder">
          {" "}
          <Logo> </Logo>{" "}
        </div>

        {level === 3 && (
          <div>
            <ScrollingText
              text={scrollingText}
              secondaryClass="leftRotateTop"
            />
            <ScrollingText
              text={scrollingText}
              scrollDirection="right"
              secondaryClass="rightRotateBottom"
            />
          </div>
        )}

        <div key={header1} style={answerStyle} className="hackableTextBlock">
          <HackableContainer
            key={header1}
            header={header1}
            text={p1}
            level={level}
            levelTexts={levelTexts1}
          />
        </div>

        <div key={header2} style={answerStyle} className="hackableTextBlock">
          <HackableContainer
            key={header2}
            header={header2}
            text={p2}
            level={level}
            levelTexts={levelTexts2}
          />
        </div>

        <div key={header3} style={answerStyle} className="hackableTextBlock">
          <HackableContainer
            key={header3}
            header={header3}
            text={p3}
            level={level}
            levelTexts={levelTexts3}
          />
        </div>

        <div key={header4} style={answerStyle} className="hackableTextBlock">
          <HackableContainer
            key={header4}
            header={header4}
            text={p4}
            level={level}
            levelTexts={levelTexts4}
          />
        </div>

        <div key={header5} style={answerStyle} className="hackableTextBlock">
          <HackableContainer
            key={header5}
            header={header5}
            text={p5}
            level={level}
            levelTexts={levelTexts5}
          />
        </div>

        <div key={header6} style={answerStyle} className="hackableTextBlock">
          <HackableContainer
            key={header6}
            header={header6}
            text={p6}
            level={level}
            levelTexts={levelTexts6}
          />
        </div>
        <Link to="/enlist">
          <button key="ENLIST" className="button" id="enlist">
            ENLIST
          </button>
        </Link>
      </div>
      <div class="signupModal">
        <ContactForm level={level} levelTexts={levelTexts7} />
      </div>
    </div>
  );
};
